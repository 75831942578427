<template>
  <div id="app">
    <router-view />
  </div>
  <notifications position="bottom center" />
</template>

<script setup>
import { socket } from "@/socket";

// remove any existing listeners (after a hot module replacement)
socket.off();
</script>
